<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 lg11 pa-0 pa-sm-6>
        <v-card
          class="mx-auto elevation-2 pa-6"
          max-width="1088px"
          align="center"
          outlined
        >
          <!----------------------filters---------------------------->
          <v-layout wrap  pb-2>
            <v-flex xs12 sm4 text-left pb-2>
              <span class="nsbold" style="font-size:20px">
                Removed Students List
              </span>
            </v-flex>
            
          <!-- </v-layout>
          <v-layout wrap justify-end> -->
              <v-flex xs12 sm3 md2 lg2 pr-1 v-if="appUser.usertype==1">
              <v-select
                :items="HostelList"
                v-model="hostel"
                label="Hostel"
                class="rounded-xl"
                item-text="hostelName"
                item-value="_id"
                clearable
                hide-details
                @input="getBlockid()"
                outlined
                dense
              ></v-select>
            </v-flex>
             <v-flex xs12 sm3 md2 lg2 pr-1 >
               <v-select
                :items="BlockIds"
                v-model="hostelblockid"
                label="Select Block"
                class="rounded-xl"
                item-text="Block"
                item-value="_id"
                hide-details
                outlined
                dense
              ></v-select>
            </v-flex>
            <v-flex xs12 sm3 md2 lg2 pr-1>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    readonly
                    dense 
                    hide-details
                    clearable
                    class="rounded-xl"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 md2 lg2 pr-1>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details
                    class="rounded-xl"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <!--------------------------list------------------------>
          </v-layout>
          <!---------------------------------------------------->
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="attendance" px-0 pt-2>
              <v-layout wrap>
                <v-flex xs12 v-if="attendance.length > 0">
              
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Adn.no</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Previous Hostel</th>
                          <th class="text-left">Block</th>
                          <th class="text-left">From</th>
                          <th class="text-left">To</th>
                          <th class="text-left">Current Hostel Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in attendance" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 50 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{item.studentid.admissionNo}}
                          </td>
                          <td>{{ item.studentid.name}}</td>
                          <td v-if="item.mainHostelId">{{item.mainHostelId.hostelName}}</td>
                          <td>{{item.blockId.Block}}</td>
                          <td>{{formatDate2(item.fromDate)}}</td>
                          <td>{{formatDate2(item.toDate)}}</td>
                          <td><span v-if="item.isAssignedAgain==false">Unassigned</span><span v-else>Assigned to {{item.presentHostelName}}</span></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 pt-4>
                   <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
                </v-flex>
              </v-layout>
             
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
      <v-dialog width="400px" v-model="viewDialoge" scrollable>
        <v-card width="400px" elevation="0">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Detailed Data </v-toolbar-title>
          </v-toolbar>
  <v-card elevation="0">
    <v-tabs
      color="deep-purple accent-4"
      right
    >
      <v-tab>Present</v-tab>
      <v-tab>Absent</v-tab>

      <v-tab-item>
         <v-layout justify-center wrap v-if="individualViewPresent">
               <v-flex xs12 v-if="individualViewPresent.length > 0" px-4>
              
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Marked Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in individualViewPresent" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 50 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{formatDate2(item)}}
                          </td>
                        </tr>
                      </tbody>
                     
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex xs12 align-self-center text-center v-else >
                  <span style="font-family:poppinsregular; font-size:18px;">No attendance found for selected date</span>
                </v-flex>
                  <v-layout wrap justify-center>
                <!-- <v-flex xs12 pt-1 text-center align-self-center>
                   <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="pageinner"
                :length="Pagelengthinner"
              >
              </v-pagination>
                </v-flex> -->
              </v-layout>
            </v-layout>
      </v-tab-item>
      <v-tab-item>
         <v-layout justify-center wrap v-if="individualViewAbsent">
               <v-flex xs12 v-if="individualViewAbsent.length > 0" px-4>
              
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Marked Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in individualViewAbsent" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 50 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{formatDate2(item)}}
                          </td>
                        </tr>
                      </tbody>
                     
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex xs12 align-self-center text-center v-else>
                  <span style="font-family:poppinsregular; font-size:18px;">No attendance found for selected date</span>
                </v-flex>
                  <v-layout wrap justify-center>
                <!-- <v-flex xs12 pt-1 text-center align-self-center>
                   <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="pageinner"
                :length="Pagelengthinner"
              >
              </v-pagination>
                </v-flex> -->
              </v-layout>
            </v-layout>
      </v-tab-item>
    </v-tabs>
  </v-card>
     
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="viewDialoge = false"
              >Close</v-btn
            >
           
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
import store from "../../store";
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      pageinner:1,
      Pagelengthinner:0,
      menu: false,
      menu2: false,
      fromDate:null,
      toDate:null,
    //   fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    //     .toISOString()
    //     .substr(0, 10),
    //   toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    //     .toISOString()
    //     .substr(0, 10),
    //     defaulttodate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    //     .toISOString()
    //     .substr(0, 10),
      keyword: null,
      msg: null,
      //    issueStatus : ["All","Approved", "Out", "Active", "In"],
      cvalue: "All",
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      attendance: [],
      HostelList: [],
      BlockIds:[],
      hostelblockid:null,
      hostel: null,
      downloadBtn:false,
      hostelwardenList:[],
      hostelwarden:null,
      individualViewPresent:[],
      individualViewAbsent:[],
      usertype1:false,
      viewDialoge: false,
       myData: [],
        loader: null,
        loading: false,
      excel: {
        adno: "",
        name: "",
        class: "",
        hostelid: "",
        warden: "",
        present: "",
        absent: "",
      },
      json_fields: {
        Ad_No: "adno",
        Name: "name",
        Class: "class",
        Hostel: "hostelid",
        Warden: "warden",
        Present_Days: "present",
        Absent_Days: "absent",
      },
    };
  },
   computed: {
    appUser() {
      return store.state.userData;
    },
  },
  mounted() {
    this.gethostel();
    // this.getBlockid();
    // if(this.appUser.usertype==0){
    //   this.usertype1=true;
    //   this.getBlockid();
    // }
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 3 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();

      // var day=dlt.getDate()
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }
    this.downloadBtn=false;
    this.getData();
    

  },
  watch: {
    hostelblockid(){
      this.downloadBtn=false;
      this.usertype1=false;
      if (this.page > 1) this.page = 1;
       this.getData();
      
    },
    page() {
      // this.getData();
      this.getData();
    },
    fromDate() {
      this.downloadBtn=false;
      if (this.page > 1) this.page = 1;
      this.getData();
      
    },
    toDate() {
      this.downloadBtn=false;
      if (this.page > 1) this.page = 1;
      
      this.getData();
    },
    hostel() {
      this.downloadBtn=false;
      this.usertype1=false;
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
      
    },
     hostelwarden() {
      this.downloadBtn=false;
      this.usertype1=false;
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
      
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      // if(this.toDate ==""){
      //  this.toDate=this.defaulttodate;
      //  console.log("todate=",this.toDate)
      //  console.log("defaulttodate=",this.defaulttodate)
      // }
      //   let a;
      //   if (this.cvalue == "All") {
      //     a = "";
      //   } else {
      //     a = this.cvalue;
      //   }
      axios({
        method: "get",
        url: "/hostel/removed/students/list",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          // id: this.$route.query.id,
          page: this.page,
          limit: 50,
          blockId:this.hostelblockid,
          from: this.fromDate,
          to: this.toDate,
           hostelId: this.hostel,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.attendance = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 50);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getBlockid() {
      this.hostelblockid = null,
      this.appLoading = true;
      axios({
        method: "get",
        url: "/subhostel/name/getlist/mainhostelwise",

        headers: {
          token: localStorage.getItem("token"),
        },
        params:{
          // wardenid:this.hostelwarden
          mainHostelId: this.hostel,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            if(response.data.data){
              if(response.data.data.length>0){
           this.BlockIds = response.data.data;
           if(this.usertype1==true){
            this.hostelblockid = response.data.data[0]._id;
           this.getData();
           }
          //  this.hostelblockid = response.data.hosteldata[0]._id;
          //  this.getData();
              }
            }
            // this.Pagelength = Math.ceil(response.data.totalLength / 50);
          }
          //  else {
          //   this.appTimes();
          // }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
      gethostel() {
      axios({
        method: "get",
        // url: "/hostel/name/getlist",
        url: "main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
          //  this.getData();

          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getHostelWarden() {
      this.hostelwarden = null,
      this.hostelblockid = null,
      this.appLoading = true;
      axios({
        method: "get",
        url: "hostel/warden/getlist/hostelwise",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          mainHostelId: this.hostel,
          // page: this.page,
          // limit: 50,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.hostelwardenList = response.data.data;
            // this.getData();
            // this.Pagelength = Math.ceil(response.data.totalLength / 50);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    formatDate2(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
<style scoped>
.buttonload {
  background-color: #04AA6D; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 24px; /* Some padding */
  font-size: 16px; /* Set a font-size */
}

/* Add a right margin to each icon */
.fa {
  margin-left: -12px;
  margin-right: 8px;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
